import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import './SeriesDetails.css';

const API_URL = 'https://my-investor-app-9622243531b6.herokuapp.com';

function SeriesDetails() {
    const { id } = useParams();
    const [details, setDetails] = useState(null);
    const [refresh, setRefresh] = useState(false);
    const [totalLosses, setTotalLosses] = useState(0);
    const [isEditing, setIsEditing] = useState(false);
    const [newSeriesName, setNewSeriesName] = useState('');
    const [currentExpenses, setCurrentExpenses] = useState(0);
    const [totalExpenses, setTotalExpenses] = useState(0);
    const [splitCount, setSplitCount] = useState(1);

    const [formData, setFormData] = useState({
        team_name: '',
        odds: '',
        stake: '',
        profit: '',
        date: new Date().toISOString().split('T')[0],
        bookmaker: ''
    });

    useEffect(() => {
        const fetchDetails = async () => {
            try {
                const response = await axios.get(`${API_URL}/api/series/${id}`);
                setDetails(response.data);
                setNewSeriesName(response.data.name);
                calculateTotalLosses(response.data.bets);
                calculateCurrentExpenses(response.data.bets);
                calculateTotalExpenses(response.data.bets);
            } catch (error) {
                console.error('Error fetching series details:', error);
            }
        };

        fetchDetails();
    }, [id, refresh]);

    const handleRefresh = () => {
        setRefresh(!refresh);
    };

    const calculateTotalLosses = (bets) => {
        const losses = bets.reduce((acc, bet) => {
            return bet.result === 'lost' ? acc + parseFloat(bet.stake) : acc;
        }, 0);
        setTotalLosses(losses);
    };

    const calculateCurrentExpenses = (bets) => {
        const expenses = bets.reduce((acc, bet) => {
            return bet.result ? acc : acc + parseFloat(bet.stake);
        }, 0);
        setCurrentExpenses(expenses);
    };

    const calculateTotalExpenses = (bets) => {
        const expenses = bets.reduce((acc, bet) => acc + parseFloat(bet.stake), 0);
        setTotalExpenses(expenses);
    };

    const markBetAs = async (betId, result) => {
        try {
            await axios.put(`${API_URL}/api/series/bets/${betId}/status`, { result });
            handleRefresh();
        } catch (error) {
            console.error(`Failed to mark bet as ${result}:`, error);
            alert(`Failed to mark bet as ${result}.`);
        }
    };

    const handleEditToggle = () => {
        setIsEditing(!isEditing);
    };

    const handleSeriesNameChange = (e) => {
        setNewSeriesName(e.target.value);
    };

    const handleSeriesNameSave = async () => {
        try {
            console.log('Saving new series name:', newSeriesName);  // Log the series name being sent
    
            const response = await axios.put(`${API_URL}/api/series/${id}/name`, { name: newSeriesName });
            console.log('Update response:', response.data);  // Log the response from the server
    
            setDetails({ ...details, name: newSeriesName });
            setIsEditing(false);
        } catch (error) {
            console.error('Error updating series name:', error);  // Log the error details
            alert('Failed to update series name.');
        }
    };
    

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleOddsChange = (e) => {
        const odds = e.target.value;
        const profit = formData.profit;
        const calculatedStake = (parseFloat(profit) + totalLosses) / (parseFloat(odds) - 1);
        setFormData({ ...formData, odds, stake: calculatedStake.toFixed(2) });
    };

    const handleProfitChange = (e) => {
        const profit = e.target.value;
        const odds = formData.odds;
        const calculatedStake = (parseFloat(profit) + totalLosses) / (parseFloat(odds) - 1);
        setFormData({ ...formData, profit, stake: calculatedStake.toFixed(2) });
    };

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        try {
            await axios.post(`${API_URL}/api/series/${details.series_id}/bets`, formData);
            setFormData({
                team_name: '',
                odds: '',
                stake: '',
                profit: '',
                date: new Date().toISOString().split('T')[0],
                bookmaker: ''
            });
            handleRefresh();
        } catch (error) {
            console.error('Failed to submit bet:', error);
            alert('Failed to submit the bet.');
        }
    };

    const handleDeleteBet = async (betId) => {
        try {
            await axios.delete(`${API_URL}/api/series/bets/${betId}`);
            handleRefresh();
        } catch (error) {
            console.error('Failed to delete bet:', error);
            alert('Failed to delete bet.');
        }
    };

    const handleSplitCountChange = (e) => {
        setSplitCount(e.target.value);
    };

    const handleSplitSeries = async () => {
        const countInt = parseInt(splitCount, 10);
        if (countInt <= 0 || countInt > 10) {
            alert('Please enter a valid number of series to split into (1-10).');
            return;
        }

        try {
            await axios.post(`${API_URL}/api/series/${id}/split`, { count: countInt });
            handleRefresh();
            alert(`Series split into ${countInt} new series successfully.`);
        } catch (error) {
            console.error('Error splitting series:', error);
            alert('Error splitting series.');
        }
    };

    if (!details) {
        return <p>Loading details...</p>;
    }

    return (
        <div className="series-details-container">
            <div className="series-summary">
                <div className="series-name">
                    <h2>📊 {isEditing ? (
                        <input
                            type="text"
                            value={newSeriesName}
                            onChange={handleSeriesNameChange}
                        />
                    ) : (
                        details.name || 'undefined'
                    )}</h2>
                    {isEditing ? (
                        <button onClick={handleSeriesNameSave}>Save</button>
                    ) : (
                        <button onClick={handleEditToggle}>Edit</button>
                    )}
                </div>
                <div className="series-info">
                    <p>📅 Créée le: {new Date(details.created_at).toLocaleDateString()}</p>
                    <p>{details.is_closed ? '🛑 Fermée' : '⏳ Ouverte'}</p>
                    <p>💰 En-cours: {currentExpenses.toFixed(2)} €</p>
                    <p>💸 Dépenses totales: {totalExpenses.toFixed(2)} €</p>
                </div>
                <div className="series-split-series" style={{ background: 'none', border: 'none', boxShadow: 'none' }}>
                    <h2>⚡ Split Series</h2>
                    <input
                        type="number"
                        value={splitCount}
                        onChange={handleSplitCountChange}
                        placeholder="Nombre de nouvelles séries"
                        min="1"
                        max="10"
                    />
                    <button onClick={handleSplitSeries}>Split</button>
                </div>
            </div>

            <div className="series-bets-list">
                <h2>📋 Historique des paris</h2>
                <table>
                    <thead>
                        <tr>
                            <th className="bet-id-col">ID</th>
                            <th className="team-col">Équipe</th>
                            <th className="bookmaker-col">Book</th>
                            <th className="cote-col">Cote</th>
                            <th className="profit-col">Profit</th>
                            <th className="stake-col">Mise</th>
                            <th className="date-col">Date</th>
                            <th className="actions-col">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {details.bets.map((bet, index) => (
                            <tr key={index}>
                                <td className="bet-id-col">{bet.bet_id}</td>
                                <td className="team-col">{bet.team_name}</td>
                                <td className="bookmaker-col">{bet.bookmaker}</td>
                                <td className="cote-col">{bet.odds}</td>
                                <td className="profit-col">{parseFloat(bet.profit).toFixed(2)} €</td>
                                <td className="stake-col">{parseFloat(bet.stake).toFixed(2)} €</td>
                                <td className="date-col">{new Date(bet.date).toLocaleDateString()}</td>
                                <td className="actions-col">
                                    <div className="action-buttons">
                                        {bet.result ? (
                                            <>
                                                <span>{bet.result === 'won' ? 'Gagné' : 'Perdu'}</span>
                                                <button onClick={() => markBetAs(bet.bet_id, bet.result === 'won' ? 'lost' : 'won')}>
                                                    {bet.result === 'won' ? '❌' : '✔️'}
                                                </button>
                                            </>
                                        ) : (
                                            <>
                                                <button onClick={() => markBetAs(bet.bet_id, 'won')}>✔️</button>
                                                <button onClick={() => markBetAs(bet.bet_id, 'lost')}>❌</button>
                                            </>
                                        )}
                                        <button onClick={() => handleDeleteBet(bet.bet_id)}>🗑️</button>
                                    </div>
                                </td>
                            </tr>
                        ))}
                        <tr>
                            <td className="bet-id-col"></td>
                            <td className="team-col">
                                <input
                                    type="text"
                                    name="team_name"
                                    value={formData.team_name}
                                    onChange={handleInputChange}
                                    placeholder="Équipe"
                                    required
                                />
                            </td>
                            <td className="bookmaker-col">
                                <select
                                    name="bookmaker"
                                    value={formData.bookmaker}
                                    onChange={handleInputChange}
                                    required
                                >
                                    <option value="">Book</option>
                                    <option value="Winamax">Winamax</option>
                                    <option value="Parions Sport">Parions Sport</option>
                                    <option value="Unibet">Unibet</option>
                                </select>
                            </td>
                            <td className="cote-col">
                                <input
                                    type="text"
                                    name="odds"
                                    value={formData.odds}
                                    onChange={handleOddsChange}
                                    placeholder="Cote"
                                    required
                                />
                            </td>
                            <td className="profit-col">
                                <input
                                    type="text"
                                    name="profit"
                                    value={formData.profit}
                                    onChange={handleProfitChange}
                                    placeholder="Profit"
                                    required
                                />
                            </td>
                            <td className="stake-col">
                                <input
                                    type="text"
                                    name="stake"
                                    value={formData.stake}
                                    readOnly
                                    required
                                />
                            </td>
                            <td className="date-col">
                                <input
                                    type="date"
                                    name="date"
                                    value={formData.date}
                                    onChange={handleInputChange}
                                    required
                                />
                            </td>
                            <td className="actions-col">
                                <button onClick={handleFormSubmit}>➕</button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default SeriesDetails;
