import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import './InvestorDetails.css';

const API_URL = 'https://my-investor-app-9622243531b6.herokuapp.com';

const InvestorDetails = () => {
    const { id } = useParams();
    const [investor, setInvestor] = useState(null);
    const [investments, setInvestments] = useState([]);
    const [bets, setBets] = useState([]);
    const [sortedBets, setSortedBets] = useState([]);
    const [sortConfig, setSortConfig] = useState({ key: 'date', direction: 'desc' });
    const [isAdmin, setIsAdmin] = useState(false);  // Admin status

    useEffect(() => {
        const init = async () => {
            await checkAdminStatus();
            await fetchInvestorDetails();
        };
        init();
    }, [id]);

    const fetchInvestorDetails = async () => {
        try {
            const token = localStorage.getItem('token');
            const response = await axios.get(`${API_URL}/api/investors/${id}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            const investorData = response.data;

            const eligibleBets = investorData.bets.filter(bet => bet.eligible);
            setInvestor(investorData);

            const sortedInvestments = investorData.investments.sort((a, b) => new Date(a.date) - new Date(b.date));
            setInvestments(sortedInvestments || []);

            setBets(eligibleBets);
            sortBets(eligibleBets);
        } catch (error) {
            console.error('Erreur lors de la récupération des détails de l\'investisseur:', error);
        }
    };

    const checkAdminStatus = async () => {
        try {
            const token = localStorage.getItem('token');
            if (!token) {
                console.error("No token found in localStorage");
                return;
            }

            const response = await axios.get(`${API_URL}/auth/me`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            const userData = response.data;
            setIsAdmin(userData.role === 'admin');
        } catch (error) {
            console.error('Error fetching admin status:', error);
        }
    };

    const handleAddInvestment = () => {
        const amountInput = prompt('Enter investment amount');
        const dateInput = prompt('Enter investment date (YYYY-MM-DD)');

        const amount = parseFloat(amountInput);
        const date = new Date(dateInput);

        if (isNaN(amount) || date.toString() === 'Invalid Date') {
            alert('Invalid amount or date. Please try again.');
            return;
        }

        addInvestment({ amount, date: dateInput });
    };

    const addInvestment = async (investment) => {
        try {
            const token = localStorage.getItem('token');
            await axios.post(`${API_URL}/api/investors/${id}/investments`, investment, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            await fetchInvestorDetails();
        } catch (error) {
            console.error('Error adding investment:', error);
        }
    };

    const handleEditInvestment = (investment) => {
        const amountInput = prompt('Enter new investment amount', investment.amount);
        const dateInput = prompt('Enter new investment date (YYYY-MM-DD)', investment.date);

        const amount = parseFloat(amountInput);
        const date = new Date(dateInput);

        if (isNaN(amount) || date.toString() === 'Invalid Date') {
            alert('Invalid amount or date. Please try again.');
            return;
        }

        updateInvestment(investment.id, { amount, date: dateInput });
    };

    const updateInvestment = async (investmentId, updatedInvestment) => {
        try {
            const token = localStorage.getItem('token');
            await axios.put(`${API_URL}/api/investors/${id}/investments/${investmentId}`, updatedInvestment, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            await fetchInvestorDetails();
        } catch (error) {
            console.error('Error updating investment:', error);
        }
    };

    const handleDeleteInvestment = async (investmentId) => {
        try {
            const token = localStorage.getItem('token');
            await axios.delete(`${API_URL}/api/investors/${id}/investments/${investmentId}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            await fetchInvestorDetails();
        } catch (error) {
            console.error('Error deleting investment:', error);
        }
    };

    const sortBets = (bets) => {
        const sorted = [...bets].sort((a, b) => {
            const dateA = new Date(a.date);
            const dateB = new Date(b.date);
            return sortConfig.direction === 'asc' ? dateA - dateB : dateB - dateA;
        });

        let cumulativeProfit = 0;
        const betsWithCumulativeProfit = sorted.map(bet => {
            cumulativeProfit += parseFloat(bet.investorProfit) || 0;
            return { ...bet, cumulativeProfit: cumulativeProfit.toFixed(2) };
        });

        setSortedBets(betsWithCumulativeProfit);
    };

    const handleSortChange = () => {
        const newDirection = sortConfig.direction === 'asc' ? 'desc' : 'asc';
        setSortConfig({ key: 'date', direction: newDirection });
        sortBets(bets);
    };

    const totalProfitEligible = sortedBets.reduce((acc, bet) => acc + (parseFloat(bet.investorProfit) || 0), 0);

    if (!investor) {
        return <p>Chargement des détails de l'investisseur...</p>;
    }

    const totalInvestment = investments.reduce((acc, investment) => acc + parseFloat(investment.amount), 0);

    return (
        <div className="investor-details-container">
            <div className="investor-summary">
                <div className="capital">💰 Capital actuel: {(totalInvestment + totalProfitEligible).toFixed(2)} €</div>
                <div className="profit">📈 Profit actuel: {totalProfitEligible.toFixed(2)} €</div>
            </div>

            <div className="investor-card">
                <h2>🧑 Informations de l'investisseur</h2>
                <p>Nom : {investor.name}</p>
                <p>Investissement total : {totalInvestment.toFixed(2)} €</p>
            </div>

            <div className="investor-card">
                <h2>💸 Investissements</h2>
                <table className="investments-table">
                    <thead>
                        <tr>
                            <th>Montant</th>
                            <th>Date</th>
                            <th>Type</th>
                            {isAdmin && <th>Actions</th>}
                        </tr>
                    </thead>
                    <tbody>
                        {investments.map((investment) => (
                            <tr key={investment.id}>
                                <td>{parseFloat(investment.amount).toFixed(2)} €</td>
                                <td>{new Date(investment.date).toLocaleDateString()}</td>
                                <td>{investment.amount < 0 ? 'Retrait' : 'Dépôt'}</td>
                                {isAdmin && (
                                    <td>
                                        <button onClick={() => handleEditInvestment(investment)}>Modifier</button>
                                        <button onClick={() => handleDeleteInvestment(investment.id)}>Supprimer</button>
                                    </td>
                                )}
                            </tr>
                        ))}
                    </tbody>
                </table>
                {isAdmin && <button onClick={handleAddInvestment}>Ajouter un investissement</button>}
            </div>

            <div className="investor-card">
                <h2>📋 Historique des paris</h2>
                <table className="bets-list">
                    <thead>
                        <tr>
                            <th>Nom de l'équipe</th>
                            <th>Cote</th>
                            <th>Mise</th>
                            <th>Profit</th>
                            <th>Date <button onClick={handleSortChange}>{sortConfig.direction === 'asc' ? '⬆️' : '⬇️'}</button></th>
                            <th>Profit cumulé</th>
                            <th>Résultat</th>
                        </tr>
                    </thead>
                    <tbody>
                        {sortedBets.map((bet) => (
                            <tr key={bet.bet_id}>
                                <td>{bet.team_name}</td>
                                <td>{parseFloat(bet.odds).toFixed(2)}</td>
                                <td>{parseFloat(bet.stake).toFixed(2)} €</td>
                                <td>{parseFloat(bet.investorProfit).toFixed(2)} €</td>
                                <td>{new Date(bet.date).toLocaleDateString()}</td>
                                <td>{bet.cumulativeProfit} €</td>
                                <td>{bet.result === 'won' ? '✔️ Gagné' : '❌ Perdu'}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default InvestorDetails;
